.playingHistoryContainer {
  padding: 0px 16px;
}

.historyTitle {
  font-size: 18px;
  margin-bottom: 16px;
  color: #232323;
  font-weight: 700;
}

.historyList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.historyItemContainer {
  background: white;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--Stroke-1, #EDEDED)
}

.historyItem {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.leftSection {
  flex: 1;
  margin-right: 16px;
}

.quizInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.quizInfo h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.quizInfo p {
  margin: 0;
  font-size: 12px;
  color: #535353;
  font-weight: 400;
}
.scoreText{
  font-weight: 600;
}
.progressSection {
  display: flex;
  align-items: center;
  gap: 16px;
}

.progressBar {
  flex: 1;
  height: 6px;
  background: #EBE8F3;
  border-radius: 3px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background: #361B83;
  border-radius: 3px;
}

.percentageText {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  white-space: nowrap;
}

.playAgainBtn {
  background: #FF1F8E;
  color: white;
  border: none;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}

.playAgainBtn:hover {
  background: #E6197F;
}