.btn-close{
  color: aqua;
}

.modal-header {
  background: #fff;
  color: #232323;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  text-align: center;
  border-bottom: 1px solid #EDEDED;
}

.modalContent {
  border-radius: 16px;
  width: 90%;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #EDEDED;
  overflow: hidden;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.modal-dialog {
  max-width: 425px;
}

.modal-body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #232323;
  background: #fff;
}

.modalButtonIcon{
  border: 1px solid #6c4e9d;
  font-size: 18px;
  color: #6c4e9d;
}