.profileUpper {
  background: radial-gradient(79.21% 88.27% at 51.11% 8.1%, #1C0461 0%, #311E66 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  padding: 24px 16px;
  color: white;
  box-shadow: 0px 2px 4px -1px #0000000F;
  box-shadow: 0px 4px 6px -1px #0000001A;

}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
}

.profileInfo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profileAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.profileName h2 {
  margin: 0;
  font-size: 1.2rem;
}

.subscriberBadge {
  background-color: #4CAF50;
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.8rem;
}

.editButton {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}

.editButton:hover {
  opacity: 0.8;
}

.scoreSection {
  margin: 20px 0;
}

.averageScore {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.averageScore p {
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}

.averageScore h3 {
  margin: 0;
}

.progressBar {
  background: #FFFFFF1A;
  height: 8px;
  border-radius: 4px;
  margin-top: 10px;
}

.progress {
  background-color: #EBE8F3;
  opacity: .6;
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.statsGrid {
  background: #FFFFFF1A;
  border-radius: 12px;
  padding: 20px 16px;
  color: white;
}

.statRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statItem {
  flex: 1;
}

.statContent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.statIcon {
  width: 24px;
  height: 24px;
}

.statText p {
  font-size: 14px;
  margin: 0;
  opacity: 0.8;
}

.statText h3 {
  font-size: 20px;
  margin: 4px 0 0 0;
}

.divider {
  width: 1px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 20px;
}

.horizontalDivider {
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin: 20px 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 480px) {
  .statsGrid {
    gap: 10px;
    padding: 10px;
  }

  .statContent p {
    font-size: 0.8rem;
  }

  .statContent h3 {
    font-size: 1rem;
  }

  .profileAvatar {
    width: 50px;
    height: 50px;
  }
}

.unsubscribed {
  background-color: #dc3545 !important;
  /* or any other red color of your choice */
}