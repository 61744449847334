.accordion-button {
  font-weight: bold;
  padding: 1rem;
  background-color: #f8f9fa;
  color: #0c63e4;
}

.accordion-button:not(.collapsed) {
  background-color: #e7f1ff;
  color: #000;
}

