.quizChallenges {
  padding: 0px;
  background: #ffffff00;
  font-family: 'Noto Sans Bengali', sans-serif;
}

.quizChallengesCategory {
  padding: 0px;
  background: #ffffff00;
  font-family: 'Noto Sans Bengali', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0px 16px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #3B3B3B;
  margin: 0;
  font-family: 'LiAdorNoirrit', sans-serif;
}

.viewAll {
  color: #FB005D;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}

.cardContainer {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  margin: 0 -16px;
  padding: 6px 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.quizCard {
  min-width: 280px;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  padding: 6px;
  box-shadow: 0px 1px 4px 0px #00000040;
  margin-bottom: 2px;
}

.bannerImage {
  width: 100%;
  aspect-ratio: 272 / 138;
  object-fit: fill;
  border-radius: 12px;
}

.quizInfo {
  padding: 8px 0;
}

.quizInfo h3 {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 600;
  color: #232323;
  font-family: 'Noto Sans Bengali', sans-serif;
  font-weight: 600;
  line-height: 21.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.quizMeta {
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: #232323;
  margin-bottom: 0px;
  font-weight: 500;
  justify-content: space-between;
}

.metaItem {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f5f5f500;
  padding: 4px 2px;
  border-radius: 4px;
}

.timeIcon,
.questionIcon {
  font-size: 16px;
  color: #232323;
}

.playButton {
  background: #e2136e;
  color: white;
  border: none;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 12px;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #ddd;
}

.activeDot {
  background: #e2136e;
}

:global(.swiper-pagination-bullet) {
  width: 6px;
  height: 6px;
  background: #E0E0E0;
  opacity: 1;
}

:global(.swiper-pagination-bullet-active) {
  background: #FF1F8E;
}

:global(.swiper-wrapper) {
  padding-left: 16px !important;
  padding-right: 32px !important;
}

:global(.swiper) {
  overflow: visible !important;
  margin-right: 32px !important;
}