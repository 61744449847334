.quizNavbar {
  width: 100%;
  height: 90px;
  padding: 20px 16px 20px 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 2px 4px -1px #0000000F;
  box-shadow: 0px 4px 6px -1px #0000001A;
  background: radial-gradient(112.5% 111.22% at 104.39% 0%, #7C57E2 0%, #1C0461 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
}

.timerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 0px;
  height: 22px;
}

.progressBar {
  flex: 1;
  height: 8px;
  background: #fff;
  border-radius: 8px;
  margin: 0 px;
}

.progressFill {
  height: 100%;
  background: #FF7B33;
  border-radius: 8px;
  transition: width 0.3s ease;
}

.timeRemaining,
.questionCounter {
  font-size: 14px;
  font-weight: 500;
  width: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  height: 24px;
  border-radius: 4px;
}

.timeRemaining {
  background: #ffffff26;
}

.circularTimeRemaining {
  display: flex;
  align-items: center;
  width: 40px;
}

.logo {
  height: 24px;
}

.scoreContainer {
  display: flex;
  align-items: center;
  height: 32px;
  justify-content: space-between;
  padding: 0px 16px;
  margin-top: 16px;
}

.scoreItem {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 4px;
  min-width: 42px;
  justify-content: flex-start;
}

.scoreCircle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}

.scoreLabel {
  color: white;
  font-weight: bold;
}

.questionBoxWrapper {
  width: 100%;
  padding: 28px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  position: relative;
  z-index: 9;
}

.questionField {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 0px 4px;
  font-weight: 700;
  color: #2E2E2E;
  min-height: 60px;
}

.bottomImage {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.optionsField {
  display: flex;
  flex-direction: column;
  gap: 16px;
}