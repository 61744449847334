.leaderBoardSelection {
  display: flex;
  justify-content: start;
  gap: 5px;
  width: 80%;
  margin: 0 auto;
  font-size: 12px;
  /* margin-left: 5%; */
  /* padding-left: 8%; */
  background: white;
  width: fit-content;
  padding: 3px;
  border-radius: 18px;
  /* overflow-x: scroll; */
  max-width: 90%;
  filter: drop-shadow(-1px 2px 4px #00000099);
  position: relative;
  top: -1%;
  overflow-x: auto;
}

.leaderboardBtn {
  text-align: center;
  font-weight: 700;
  padding: 8px 8px;
  /* border: 2px solid #fff; */
  border-radius: 25px;
  cursor: pointer;
  color: #5257E1;
  font-size: small;
  background: #fff;
  text-wrap: nowrap;
  /* max-width: 30%; */
}

/* .leaderboardBtn-1{
  border-top-left-radius: 22px;
}
.leaderboardBtn-2{
  border-top-right-radius: 22px;
} */
.activeLeader {
  color: #fff;
  background: #5257E1;
  filter: drop-shadow(0px 0px 1px #000000bb);
}

.leaderBoardBody {
  font-size: .92em;
}

.leaderboardTableBody,.leaderboardTableHead {
  font-weight: bolder;
  color: #6c4e9d;
}
th, tr,
td {
  height: 40px;
  text-align: center;
  padding: 0 5px;
}
.tdSmallAvatarImg {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #fff;
}

.tdTag {
  width: 10%;
  text-align: end;
}

.tdBadge {
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tdRank {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6a1bda;
  font-weight: 600;
}

.tdSmallAvatar {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: none;
  background: #6a1bdaaa;
}

.perRow{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 4dvh;
  padding-left: 3.5%;
}
.tdMobileNumber {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-left: none;
  border-right: none;
  background: #6a1bdaaa;
}

.tdTime {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-left: none;
  border-right: none;
  background: #6a1bdaaa;
}
.tdScore {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: none;
  background: #6a1bdaaa;
}
.inactive{
  opacity: .3 !important;
}
.leaderBoardWrapper {
  width: 100%;
  margin-left: 0%;
  /* background: url(../../assets/img/leaderboard/leaderboardBG.png); */
  /* background: #1600AC22; */
  overflow: hidden;
  background-size: cover;
  height: 72dvh;
  /* border-radius: 30px; */
  /* border: 1px solid #fff; */
  display: flex;
  flex-direction: column;
}

.hallOfFame{
  height: 25dvh;
  /* background: #1600AC66; */
  /* border: 2px solid #fff; */
  /* border-top-right-radius: 30px; */
  /* border-top-left-radius: 30px; */
  display: flex;
  align-items: end;
  justify-content: center;
  padding: 5px;
  padding-bottom: 4%;
  position: relative;
}
.insideBorder{
  border: 2px solid #4501a399;
  width: 93%;
  height: 100%;
  margin: 0 auto;
  border-radius: 10px;
  background: #4501a322;
  position: absolute;
  top: 0px;
}
.rankOneImg{
  /* width: 100%; */
  height: 55%;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
  filter: drop-shadow(-1px 3px 6px #000000bb);
}
.rankTwoImg{
  /* width: 90%; */
  height: 38%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(-1px 3px 6px #000000bb);
}
.rankThreeImg{
  /* width: 90%; */
  height: 38%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(-1px 3px 6px #000000bb);
}
.crownImg{
  position: absolute;
  right: 12%;
  top: -9%;
  width: 14dvh;
  filter: drop-shadow(-1px 3px 6px #000000bb);
  z-index: 11;
}
.rankTable{
  flex-grow: 1;
  /* background-color: #1600AC22; */
  max-height: 40dvh;
  overflow: auto;
  z-index: 1;
}

.rankTable > .perRow{

}

.userRow{
  height: 7dvh;
  min-height: 60px;
  z-index: 1;
  /* margin-left: 2.5%; */
  width: 100%;
  /* background: #1600AC22; */
  /* border: 2px solid #fff; */
  /* border-bottom-right-radius: 28px; */
  /* border-bottom-left-radius: 28px;*/
}





.leaderLoginBtn {
  background: #342061;
  color: white;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  margin-top: 10px;
  border: none;
}

.userBoardBody {
  box-shadow: 0px 4px 5px lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  padding-bottom: 5px;
  color: #6c4e9d;
  font-weight: bolder;
}

.boardrank {
  font-size: 18px;
  font-weight: bolder;
  background: #6c4e9d;
  width: 100%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin-bottom: 4px;
  color: white;
  text-align: center;
  padding: 3px 0px;
}

.boardMobile {
  font-size: .8em;
}

.boardScore {
  font-size: .8em;
}

.boardAvatar{
  width: 30%;
}

@media (min-height: 750px) {
  .crownImg{
    top: -5%;
    right: 4%;
  }
}
@media (min-height: 700px) {
  .hallOfFame{
    /* top: -5%; */
    height: 27dvh;
  }
}

.userRow > .perRow > .tdSmallAvatar,.userRow > .perRow > .tdMobileNumber, .userRow > .perRow > .tdTime, .userRow > .perRow > .tdScore{
  /* background-color: #2ed960e6; */
  background-color: #4501a3;
}