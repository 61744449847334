
.opening-loader{
  width: 100%;
  height: 100%;
  background-color: #43335366;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@keyframes fadeInOut {
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
}

.logo-opening-loader{
  animation: fadeInOut 1.5s ease-in-out infinite;
  width: 25%;
}
.opening-loader-text{
  color: #fff;
  font-size: 1.2rem;
}
