.timerContainer {
  width: 40px;
  height: 40px;
}

.timerBackground {
  stroke: #C1B8D9;
}

.timerProgress {
  stroke: #361B83;
  /* transition: stroke-dashoffset 0.1s linear; */
} 