:root {
  --bottom-bar-height: 78px;
  --nav-item-width: 50px;
  --icon-size: 24px;
  --font-size-small: 12px;
  --primary-color: #FB005D;
  --text-color: #666;
  --font-family: 'Noto Sans Bengali', sans-serif;
}

.ppm_bottom_bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  height: var(--bottom-bar-height);
}

.bottom_nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
}

.nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: var(--nav-item-width);
}

.nav_item img {
  width: var(--icon-size);
  height: var(--icon-size);
  margin-bottom: 4px;
}

.nav_item span {
  font-family: var(--font-family);
  font-size: var(--font-size-small);
  font-weight: 400;
  line-height: 1.325;
  text-align: center;
  color: var(--text-color);
}

.nav_item.active,
.nav_item.active span {
  color: var(--primary-color);
}

.khelunImg {
  position: relative;
  bottom: 55px;
  width: 10px;
}

.khelunImg img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}