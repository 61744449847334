.landingPage{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(./bg_land.png);
  background-size: cover;
  background-position: center;
}

.landingImg{
  width: 100%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  /* height: 100%; */
  max-height: 900px;
}

.tcButton {
  padding: 10px 15px;
  color: #f5f5f5;
  background-color: #6c4e9d;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1;
  width: fit-content;
  cursor: pointer;
  left: 50%;
  top: 76%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.tcButton:hover {
  background-color: #f0f0f0;
  color: #6c4e9d;
}