/* profile page */
@media (min-width: 470px) {
  ::-webkit-scrollbar {
    width: 3px;
    /* height: 100%; */
  }
  ::-webkit-scrollbar-button {
    background: #ccc
  }
  ::-webkit-scrollbar-track-piece {
    background: #888
  }
  ::-webkit-scrollbar-thumb {
    background: #eee
  }
}
.profileWrapper{
  width: 100%;
  padding: 0px 0px 32px 0px;
}