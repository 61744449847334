.container{
  padding: 24px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mainNav {
  width: 100%;
  display: flex;
  background: #F8F9FA;
  padding: 4px;
  border-radius: 8px;
  gap: 0px;
  box-shadow: 0px 0px 2px 0px #00000026;
}

.mainNavButton {
  border: none;
  background: transparent;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  width: 50%;
  padding: 8px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainNavActive {
  background: #3F1D91;
  color: white;
}

.mainNavButton:hover:not(.mainNavActive) {
  background: rgba(63, 29, 145, 0.1);
} 