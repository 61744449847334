.header-img-div {
  display: flex;
  justify-content: space-around;
  width: 90%;
  height: 8dvh;
  margin-top: 0dvh;
  margin: 0 auto;
  position: relative;
  background: #fff;
  /* background: url(../../assets/img/v2/playerCardPrev_white.png); */
  border-radius: 20px;
  align-items: center;
  /* filter: drop-shadow(-1px 3px 5px #000000bb); */
  border: 1px solid #4501a3;
}

.header-img {
  margin: 0 auto;
  width: 100%;
}

.headerDailyScore {
  position: absolute;
  bottom: 20%;
  left: 30%;
  width: 60px;
  color: #6c4e9d;
  font-weight: bolder;
  text-align: center;
}

.headerEventScore {
  position: absolute;
  bottom: 20%;
  right: 16%;
  width: 66px;
  color: #6c4e9d;
  font-weight: bolder;
  text-align: center;
}

.quizCard {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 3dvh;
  gap: 2dvh;
}

.banner-avatarImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerAvatar-div {
  /* width: 18%; */
  border-radius: 100%;
  /* position: absolute; */
  top: 8px;
  left: 5%;
  height: 70%;
  overflow: hidden;
  margin-left: 5%;
  aspect-ratio: 1/1;
}

.userEventScore {
  display: flex;
  justify-content: space-around;
  width: 83%;
  height: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #4501a3;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 5px;
}

.singleEventScore {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 30%;
  /* min-width: 40%; */
}

.horizontalBar{
  height: 70%;
  /* width: 3px; */
  min-width: 1%;
  background-color: #4501a3;
  margin: auto 0;
  border-radius: 3px;
}

.bannerSwiperSlide {
  width: 80% !important;
  /* height: 90% !important; */
}

.quizCard2 {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin: 0 auto; */
  /* gap: 2vh; */
  margin: 0 auto;
  margin-top: 3dvh;
}

/* .banner-avatarImg{
  width: 18%;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 5%;
  height: 70%;
  object-fit: cover;
} */

.rewardBottomSwiper {
  width: 90%;
  height: 40dvh;
  margin: 0 auto;
  bottom: 10dvh;
  position: absolute;
  margin-left: 5%;
}
.rewardBottomSwiperMain{
  /* width: 100%; */
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
}
.rewardBannerSwiperSlide{
  background-position: center;
  background-size: cover;
  width: 65% !important;
  height: 90% !important;
}
.rewardBannerSwiperSlide img{
  display: block;
  /* width: 100%; */
  margin: 0 auto;
  height: 100%;
}
@media (min-height: 650px) {
  .rewardBottomSwiper {
    height: 42dvh;
  }
}
@media (min-height: 700px) {
  .rewardBottomSwiper {
    height: 45dvh;
  }
  .rewardBannerSwiperSlide img{
    height: 85%;
  }
}
@media (max-height: 700px) {
  .bannerDiv {
    max-height: 60dvh;
    margin-top: 0em;
    gap: .5em;
  }
}
@media (min-height: 700px) {
  .bannerDiv {
    max-height: 66dvh;
  }
}
.bannerDiv{
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  gap: 1em;
  max-height: 66dvh;
  overflow-y: auto;
  padding: 3%;
}

.singleBanner{
  /* overflow: hidden; */
  border-radius: 10px;
  filter: drop-shadow(0px 4px 5px #000000bb);
}

.categoryQuizText{
  color: rgb(69, 1, 163);
  font-weight: 600;
  font-size: 2.2dvh;
  z-index: 77;
  /* border: 1px solid rgb(69, 1, 163);
  padding: 3px 10px;
  border-radius: 9px; */
}
.referMarque{
  width: 100%;
  z-index: 9;
  background: url('./referAssets/scrollBG.png');
  overflow: hidden;
}
.marquee{
  /* width: 100%; */
  height: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
  animation: marquee 15s linear infinite;
  flex-wrap: nowrap;
  /* overflow: hidden; */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-300%); /* adjust the translation value based on the total width of the images */
  }
}

.referImage{
  width: 90px;
  position: absolute;
  right: 0;
  top: 52%;
  transform: translate(100px,0%);
  display: none;
  opacity: 0;
}

.tournamentQuizBanner,.dailyQuizBanner{
  filter: drop-shadow(-1px 1px 1px #9652f5bb);
}

.swiper-pagination-bullet-active{
  background: #361B83 !important;
}