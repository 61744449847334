.container {
  padding: 24px 16px;
  border-radius: 8px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #232323;
  margin-bottom: 16px;
  font-family: 'LiAdorNoirrit', sans-serif;
}

.totalAmount {
  background: #EBE8F3;
  padding: 4px 10px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  border: 1px solid var(--White, #FFFFFF);
  justify-content: center;
}

.totalLabel {
  color: #757575;
  font-size: 14px;
}

.amount {
  color: #232323;
  font-weight: 700;
  font-size: 16px;
  margin-left: 8px;
}

.referralList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.referralItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  background: #fff;
  border: 1px solid var(--Stroke-1, #EDEDED);
  border-radius: 8px;
}

.referralItem:last-child {
  border-bottom: none;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.playerName {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.enrollmentDate {
  font-size: 14px;
  color: #666666;
}

.referralAmount {
  font-size: 16px;
  font-weight: 500;
  color: #00A651;
}