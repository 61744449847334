.bannerPageBody {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 16px 0px 32px 0px;
}

.wordlyPromoDiv, .newYearPromoDiv {
  width: 100%;
  height: 100%;
  padding: 0px 16px;
}

.wordlyPromo, .newYearPromo {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
