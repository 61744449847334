.referral-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.referral-top {
  width: 100%;
  background: linear-gradient(90.7deg, #4f3d8b 2.04%, #b73563 92.39%);
  background-size: cover;
  position: relative;
  z-index: 2;
  padding: 16px;
}
.referral-top-content {
  font-size: 20px;
  color: white;
  text-align: center;
  font-weight: 600;
}
.referral-top-description {
  font-size: 14px;
  color: #f3f3f3;
  text-align: justify;
  margin-bottom: 6px;
}
.referral-bottom {
  flex: 1;
  width: 100%;
  /* background-color: red; */
  position: relative;
}

.refferral-login-container {
  /* width: 90%; */
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 30px;
  left: 5%;
  /* transform: translateY(65px); */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* filter: drop-shadow(0px 2px 2px #ccc); */
  margin: 0 auto;
}
.referral-code-desc {
  font-size: 20px;
  color: #232323;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  /* filter: drop-shadow(1px 1px 1px #8082f1); */
}
.referral-bottom-content {
  height: calc(100% - 70px);
  width: 100%;
  /* background-color: #FFD33C; */
  /* position: absolute; */
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 5%;
}

.referral-bottom-content-item {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 2px 5%;
}

.referral-bottom-content-title {
  font-size: 16px;
  /* color: #f3f3f3; */
  /* text-align: center; */
  font-weight: 500;
}

.referral-bottom-content-icon {
  height: 8.5dvh;
  filter: drop-shadow(1px 1px 1px #ccc);
}

.referral-bottom-content-icon-container {
  display: flex;
  gap: 10%;
  justify-content: space-around;
}
