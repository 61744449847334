.contactVis {
  width: 35%;
}

.socialIconBox {
  font-size: xx-large;
}

.loadingSpinner {
  /* height: ; */
  color: #7553c5;
  width: 2.5em;
  height: 2.5em;
}

.shareContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  /* Adjust the gap between the divs */
  margin-top: 10px;
}

.socialBox {
  background-color: lightblue;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
}

.messenger-icon {
  background: linear-gradient(to bottom right, #4267b2, #35549f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.shareContainer.disabled {
  opacity: 0.5;
  /* Makes the container appear disabled */
  pointer-events: none;
  /* Prevents any mouse events */
}

.socialBox {
  cursor: pointer;
  /* Show pointer cursor on hover */
}

.termsInReferral {
  display: flex;
  font-size: 10px;
  justify-content: flex-end;
  width: 60%;
  margin-top: 10px;
}

.copyCodeSpan:active {
  color: rgb(24, 219, 24)
}

.nothing {
  margin: 0;
  left: 6%;
  position: absolute;
  top: 50%;
  transform: translateY(-54%);
  width: 88%;
}

.restOftheWorld{
  padding: 5%;
  width: 100%;
  border: 1px solid #7474ED;
  color: #7553c5;
  border-radius: 15px;
}

.backArrowReferral{
  color: #7553c5;
  font-size: 3.5dvh;
}
.backArrowReferral svg{
  font-size: 30px;
}

.linkDisabled{
  pointer-events: none;
  opacity: 0.5;
}