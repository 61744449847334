.accordion-button {
  font-weight: bold;
  padding: 12px;
  background-color: #fff;
  color: #232323;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #361B83 !important;
  border-bottom: 1px solid #EDEDED;
}