.container {
  padding: 24px 16px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #232323;
  font-family: 'LiAdorNoirrit', sans-serif;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 12px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.eventTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000000;
}

.scoreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.score {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.rank {
  font-size: 14px;
  color: #000000;
}
