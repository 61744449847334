.redeemButton{
  width: 30%;
  border-radius: 20px;
  background: #1600ac;
  color: white;
  margin: 0 auto;
}

.inputBox{
  width: 100%;
  border-radius: 7px;
  border: 1px solid rgb(139, 77, 190);
  text-align: center;
  -webkit-user-select: text;
}

.inputBox:focus{
  outline: none;
}