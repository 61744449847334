.mobileNumber {
  width: 80%;
  font-size: 16px;
  height: 30px;
  border-radius: 5px;
  border: none;
}

.mobileNumber:focus {
  border: none;
  outline: none;
}

.inputSpan {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  padding: 10px 16px;
}

.numberSubmit {
  width: 100%;
  border-radius: 28px;
  background: #FB005D;
  color: white;
  margin: 8px auto 0px auto;
  padding: 7px 10px;
}

.errorOutline {
  outline: 2px solid red;
}

.inputFieldMobile {
  -webkit-user-select: text;
}

.loginModalDialog {
  padding: 16px;
}

.loginModalContent {
  padding: 44px 20px;
  border-radius: 16px;
}

.loginIcon {
  width: 54px;
}

.xImg {
  width: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.loginModalBody {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: #232323;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.infoText {
  font-size: 20px;
  font-weight: 600;
}

.inputContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
}

.inputFieldMobile{
  width: 100% !important;
}