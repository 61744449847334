.rewardsModal{
  padding: 24px 16px 32px 16px;
}

.rewardsModalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0 0;
}

.rewardsModalIcon {
  width: 64px;
  height: 64px;
}

.rewardsModalTitle {
  font-size: 18px;
  font-weight: 700;
  color: #232323;
}

.accordion {
  margin-top: 1rem;
}

.accordionItem {
  border: none;
  background: #FFFFFF !important;
  border-radius: 8px !important;
  overflow: hidden;
  margin-bottom: 0.5rem;
  border: 1px solid var(--Stroke-1, #EDEDED)
}

.accordionButton {
  /* padding: 1rem; */
  background: #FFFFFF;
  border-radius: 8px !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.accordionButton:not(.collapsed) {
  background: #FFFFFF;
  color: #4318FF; /* Purple color for active state */
}

/* Change the default arrow icon */
.accordionButton::after {
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

.accordionButton:not(.collapsed)::after {
  transform: rotate(0deg);
}

.accordionBody {
  padding: 1rem;
  background: #FFFFFF;
  color: #666666;
}

/* Style for the trophy icons and text in weekly prizes */
.accordionBody ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0.5rem;
}

.accordionBody li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.accordionBody li::before {
  content: "🏆";
  margin-right: 0.5rem;
  filter: hue-rotate(190deg) saturate(100%);
}

/* Style the unsubscribe button */
.refundBtn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.refundBtn:hover {
  background-color: #c82333;
}

/* Style links */
.accordionBody a {
  color: #0d6efd;
  text-decoration: none;
}

.accordionBody a:hover {
  text-decoration: underline;
} 