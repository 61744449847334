

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  color: #000;
  padding: 0;
  width: 24px;
  height: 24px;
  line-height: 1;
}

.closeButton:hover {
  opacity: 0.7;
}


.quizCard {
  min-width: 280px;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  padding: 6px;
  box-shadow: 0px 1px 4px 0px #00000040;
  margin-bottom: 18px;
}

.bannerImage {
  width: 100%;
  aspect-ratio: 272 / 138;
  object-fit: fill;
  border-radius: 12px;
}

.quizInfo {
  padding: 8px 0;
}

.quizInfo h3 {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 600;
  color: #232323;
  font-family: 'Noto Sans Bengali', sans-serif;
  font-weight: 600;
  line-height: 21.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.quizMeta {
  display: flex;
  gap: 16px;
  font-size: 14px;
  color: #232323;
  margin-bottom: 0px;
  font-weight: 500;
  justify-content: space-between;
}

.metaItem {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f5f5f500;
  padding: 4px 2px;
  border-radius: 4px;
}

.timeIcon,
.questionIcon {
  font-size: 16px;
  color: #232323;
}

.playButton {
  background: #e2136e;
  color: white;
  border: none;
  padding: 6px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}