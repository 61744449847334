.title {
  font-size: 18px;
  margin-bottom: 20px;
  color: #232323;
  font-weight: 700;
  font-family: 'LiAdorNoirrit', sans-serif;
}

.subscriptionBox {
  border: 1px solid #1AAA55;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 20px;
  background: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.type {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 8px 16px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  line-height: 18px;
  position: relative;
  left: -16px;
}

.badge {
  background: #e8f5e9;
  color: #2e7d32;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.price {
  margin-bottom: 20px;
}

.amount {
  font-size: 20px;
  font-weight: 700;
  color: #232323;
  margin-right: 10px;
}

.duration {
  color: #666;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.feature {
  color: #555;
  display: flex;
  align-items: center;
  gap: 8px;
}

.feature::before {
  content: "✓";
  color: #4caf50;
}

.subscribeButton {
  width: 180px;
  padding: 3px 10px;
  background-color: #FF0066;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 16px auto 0px auto;
}

.subscribeButton::after {
  content: '→';
  font-size: 20px;
}

.subscribeButton:hover {
  background-color: #e6005c;
} 