.contactVis {
  width: 35%;
}
.socialIconBox {
  font-size: xx-large;
  color: #1600ac !important;
  
}
.socialIconBox > a{
  color: #1600ac !important;
  margin-right: 5px;
}

.contactMobile > a, .contactEmail > a, .helplineTime{
  color: #1600ac !important;
  text-decoration: none;
}

.modalContentHelp{
  border-radius: 20px;
  width: 85%;
  margin: 0 auto;
  background: #ffffffdd;
  border: 2px solid #fff;
}
.fa-imo {
  height: 1.7rem;
  display: inline-block;
  background-image: url('./imo.png'); /* Path to your IMO image */
  background-size: cover;
  filter: drop-shadow(1px 1px 1px #ccc);
  aspect-ratio: 1 / 1;
}