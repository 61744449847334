.timerBox {
  height: 5px;
  width: 80%;
  margin: 0 auto;
  background: #ccc;
  border-radius: 30px;
}

.totalTimerTop {
  height: 100%;
  width: 100%;
  background: yellow;
  border-radius: 30px;
}

.progressBox {
  height: 2dvh;
  width: 80%;
  margin: 0 auto;
  /* background: #ccc; */
  border-radius: 30px;
  border: 2px solid white;
}

.progressTop {
  height: 100%;
  width: 0%;
  background: white;
  border-radius: 30px;
  transition: width .2s;
}


.timerLinear {
  height: 4dvh;
  width: 30%;
  position: absolute;
  max-height: 24px;
  right: -30%;
  top: 0px;
  /* background: #ccccaa99; */
}

.newTimerBox {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-weight: 900;
}



.Score-div {
  width: 80%;
  margin: 0 auto;
  min-width: 160px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.tickImg {
  height: 20px;
}

.questionField {
  color: white;
  width: 100%;
  margin: 0 auto;
  font-size: 3dvh;
  text-align: left;
  font-weight: 600;
  /* padding-top: 40px; */
  width: 90%;
  padding-bottom: 10px;
}

.questionFieldWrapper {
  position: absolute;
  /* box-shadow: rgb(49, 53, 55) 0px 4px 5px; */
  width: 80%;
  height: 80%;
  /* height: 50vh; */
  /* bottom: 0; */
  display: flex;
  align-items: center;
}

.questionFieldOuter {
  position: absolute;
  /* box-shadow: rgb(49, 53, 55) 0px 4px 5px; */
  width: 75%;
  height: 53dvh;
  bottom: 0;
  display: flex;
  border: 2px solid whitesmoke;
  border-left: none;
  align-items: center;
  border-top-right-radius: 60px;
}

.questionBox {
  width: 100%;
  margin: 0 auto;
  /* background: url('../../../assets/img/v2/gameplayBox3D.png'); */
  background-size: cover;
  padding-bottom: 10px;
  border-radius: 10px;
  min-height: 450px;
  position: absolute;
  bottom: 18dvh;
}

.optionsWrapper {
  width: 42%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: -5dvh;
  height: 50dvh;
}

.optionsField {
  display: flex;
  flex-direction: column;
  gap: 1dvh;
  justify-content: flex-start;
}

.singleOptions {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #ffffff;
  min-height: 45px;
  padding: 12px;
  box-shadow: 0px 0px 4px 0px #00000033;
  color: #2E2E2E;
}



.bg_right {
  background-color: #CFF3DD !important;
  /* color: #fff !important; */
}
.bg_right > .op_circle {
  background-color: #189B4D !important;
}
.bg_wrong {
  background-color: #FFD3D3 !important;
  /* color: #fff !important; */
}
.bg_wrong > .op_circle {
  background-color: #C71E1E !important;
}
.op_circle {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  background-color: white;
}


.circle1 {
  position: absolute;
  top: -16px;
  right: 84px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;
}

.circle2 {
  position: absolute;
  left: 124px;
  bottom: -26px;
  height: 52px;
  width: 52px;
  border-radius: 50%;
  background-color: white;
}

.op{
  text-align: left;
}