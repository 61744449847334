.categoryBox{
  padding: 0px 16px;
}
.title {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 700;
  color: #232323;
  font-family: 'LiAdorNoirrit', sans-serif;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  max-width: 600px;
  margin: 0 auto;
}

.categoryItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 0px 0px 2px 0px #00000026;
}

.categoryItem:hover {
  transform: translateY(-2px);
}

.iconWrapper {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.categoryItem span {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #232323;
}